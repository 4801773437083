import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { GroupByPipe } from '@z-trippete/angular-pipes';
import { get, isNil } from 'lodash';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { SubSink } from 'subsink';

import { selectAllProperties } from '../../core/+state/core.reducer';
import { IProperty } from '../../features/commons/properties/models/property.model';
import { MultiselectOption } from '../../models';
import { TypedSimpleChanges } from '../../models/types/simple-changes-typed';
import { RootState } from '../../root-store/root-state';

@Component({
  selector: 'by-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiSelectComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSelectComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() type = '';

  @Input() options: MultiselectOption[];

  @Input() label = '';

  @Input() value = '';

  @Input() disabledPath = 'disabled';

  @Input() showIconEye = false;

  @Input() noEmpty = false;

  @Input() defaultData: any[] = [];

  @Input() groupByKey: string = null;

  @Input() filterKeys: any[];

  @Input() groupByLabel: string = null;

  @Input() groupIcon: string = null;

  @Input() groupsNames: { [groupId: number]: any[] } = null;

  @Input() mode = 'multiple';

  @Input() dropdownClassName = '';

  @Input() placeholderTranslateKey = 'selected_options';

  @Input() nzAllowClear = true;

  @Input() nzShowArrow = true;

  @Input() nzServerSearch = false;

  @Input() selectedCountCustom: TemplateRef<any>;

  @Input() customDropdown: TemplateRef<any>;

  @Input() customTemplate: TemplateRef<{ $implicit: NzOptionComponent }>;

  @Input() groupLabelTpl: TemplateRef<any>;

  @Input() optionTpl: TemplateRef<any>;

  @Input() size: 'large' | 'small' | 'default' = 'default';

  @Input() loading: boolean;

  @Input() emitOnClosed = false;

  @Input() showSearch = true;

  @Input() placeholder: string | TemplateRef<any>;

  @Input() dropdownMatchSelectWidth = false;

  @Input() autoClearSearchValue = false;

  @Input() byClass = '';

  @Input() compareFn: (o1: any, o2: any) => void = (o1: any, o2: any) =>
    o1 === o2;

  @Input() optionsGroupByKey: Array<{
    value: any[];
    key: any;
    order: number;
  }>;

  @Input() sortGroupsByProperty = false;

  @Input() disabledAreRequired = false;

  @Input() nzDisabled = false;

  @Output() openChange = new EventEmitter<boolean>();

  @Output() blur = new EventEmitter();

  @Output() search = new EventEmitter<string>();

  allProperties$ = this.store.pipe(select(selectAllProperties));

  allProperties: IProperty[];

  onChange: any;

  infinity = Infinity;

  currentValue: any[] = [];

  disabled: boolean;

  @ViewChild(NzSelectComponent, { static: true })
  selectComponent: NzSelectComponent;

  private subs = new SubSink();

  constructor(
    private groupBy: GroupByPipe,
    private store: Store<RootState>,
  ) {}

  ngOnChanges(
    changes: TypedSimpleChanges<{
      options?: any[];
      groupByKey: string;
      filterKeys?: any[];
    }>,
  ): void {
    if (changes?.options && this.options?.length) {
      this.setGroupByKeyOptions();
    }

    if (changes.groupByKey && this.groupByKey && this.options?.length) {
      this.setGroupByKeyOptions();
    }

    if (changes.filterKeys && this.filterKeys) {
      this.setGroupByKeyOptions();
    }
  }

  ngOnInit() {
    this.subs.add(
      this.allProperties$.subscribe((allProperties) => {
        this.allProperties = allProperties;

        this.sortOptionsGroup();
      }),
    );
  }

  setGroupByKeyOptions(): void {
    if (!this.groupByKey || !this.options?.length) {
      return;
    }

    this.optionsGroupByKey = this.groupBy.transform(
      this.options || [],
      this.groupByKey || '',
    );

    if (this.filterKeys) {
      this.filterOptionsGroup();
    }

    this.sortOptionsGroup();
  }

  filterOptionsGroup() {
    this.optionsGroupByKey = this.optionsGroupByKey?.filter(
      (group) => this.filterKeys.some((key) => key == group.key), //Not strict since keys are valued as string for some reason need to check that
    );

    this.currentValue = this.currentValue.filter((selectedValue) =>
      this.optionsGroupByKey.some((group) =>
        group.value.some(
          (value) => get(value, this.value, value) === selectedValue,
        ),
      ),
    );

    if (this.onChange) {
      this.onChange(this.currentValue);
    }
  }

  sortOptionsGroup(): void {
    this.optionsGroupByKey = this.optionsGroupByKey?.map((group) => {
      const property = this.allProperties?.find(
        ({ id }) =>
          id === +group.key &&
          (this.groupByKey === 'property_id' ||
            this.groupByKey === 'propertyId'),
      );

      let order = get(group, ['value', 0, 'orderGroup']);

      if (this.sortGroupsByProperty) {
        order = isNil(order) ? property?.order : order;
      }

      return {
        ...group,
        order,
      };
    });

    this.optionsGroupByKey = this.optionsGroupByKey?.sort(
      (a, b) => a.order - b.order,
    );
  }

  writeValue(value: any): void {
    if (this.noEmpty && (value || []).length === 0) {
      if (this.defaultData && this.defaultData.length) {
        this.currentValue = [...this.defaultData];
      } else if (this.options && this.options.length) {
        const fisrtAvailableOption = get(this.enabledOptions, 0, null);

        this.currentValue = [
          fisrtAvailableOption
            ? get(fisrtAvailableOption, this.value, fisrtAvailableOption)
            : null,
        ];
      }
    } else {
      this.currentValue = value || [];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;

    this.setGroupByKeyOptions();
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }

  onModelChange(values: any[]) {
    const hasSelectAll =
      this.mode !== 'default' ? values.find((value) => value === 'all') : null;
    if (hasSelectAll) {
      this.handleSelectAll();
    } else {
      this.currentValue = values || [];
    }
    if (this.emitOnClosed && this.selectComponent.nzOpen) {
      return;
    }
    this.writeValue(
      this.mode !== 'default'
        ? this.currentValue.filter((option) => option !== 'all')
        : this.currentValue,
    );
    this.onChange(this.currentValue);
  }

  onClose() {
    this.selectComponent.setOpenState(false);
  }

  onOpenChanged(open: boolean) {
    this.openChange.emit(open);
    if (open) {
      return;
    }

    this.writeValue(
      this.mode !== 'default'
        ? this.currentValue.filter((option) => option !== 'all')
        : this.currentValue,
    );

    if (this.emitOnClosed && !open) {
      this.onChange(this.currentValue);
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return c.valid ? null : c.errors;
  }

  onSearch(value: string) {
    this.search.emit(value);
  }

  private handleSelectAll() {
    let options = this.options;

    if (this.filterKeys && this.optionsGroupByKey) {
      options = this.optionsGroupByKey.reduce(
        (filteredOptions: any[], option) => {
          return [...filteredOptions, ...option.value];
        },
        [],
      );
    }

    if (!this.disabledAreRequired) {
      options = options.filter(
        (option) => !get(option, this.disabledPath, false),
      );
    }

    const requiredOptions = this.disabledAreRequired
      ? options.filter((option) => get(option, this.disabledPath, false))
      : [];

    this.currentValue =
      (this.currentValue || []).length !== options.length
        ? options.map((option) => get(option, this.value, option))
        : requiredOptions.map((option) => get(option, this.value, option));
  }

  private get enabledOptions() {
    return this.options.filter(
      (option) => !get(option, this.disabledPath, false),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

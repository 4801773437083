import { Directive, HostListener, Input, OnChanges } from '@angular/core';
import { isNil } from 'lodash';
import { NzInputNumberComponent } from 'ng-zorro-antd/input-number';

import { replaceAll } from '../../../helpers/replace-all';

@Directive({
  selector: '[byDecimalOnly]',
})
export class DecimalOnlyDirective implements OnChanges {
  @Input() nzParser: (value: string) => string;

  originalParser: (value: string) => string;

  myParser = (value: string) => {
    value = replaceAll(value, ',', '.');

    if (this.nzParser) {
      value = this.nzParser(value);
    } else {
      value = this.originalParser(value);
    }

    const arrayString = value.split('.');
    return arrayString.splice(0, 2).join('.') + arrayString.join('');
  };

  constructor(private nzInputNumber: NzInputNumberComponent) {
    this.originalParser = this.nzInputNumber.nzParser;
    this.nzInputNumber.nzParser = this.myParser;
  }

  ngOnChanges() {
    this.nzInputNumber.nzParser = this.myParser;
  }

  @HostListener('keypress', ['$event'])
  public decimalOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    if (
      charCode !== 44 &&
      charCode !== 46 &&
      charCode !== 45 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57)
    ) {
      return false;
    }
    return true;
  }
}

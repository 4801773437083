import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'toCurrency',
})
export class ToCurrencyPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe,
    private translate: TranslateService,
  ) {}
  transform(value: string | number, symbol: string): string {
    value = +value;
    return `${value < 0 ? '-' : ''}${
      symbol === null || (!value && value !== 0) ? '' : symbol
    } ${this.currencyPipe.transform(
      value < 0 ? value * -1 : value,
      null,
      '',
      '1.2-2',
      this.translate.currentLang,
    )}`;
  }
}

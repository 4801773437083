<div
  class="replace-component-container"
  [ngClass]="containerClasName ? containerClasName : null"
>
  <div class="default-element">
    <ng-container *ngTemplateOutlet="defaultElement"></ng-container>
  </div>
  <div class="hover-element">
    <ng-container *ngTemplateOutlet="hoverElement || defaultElement"></ng-container>
  </div>
</div>

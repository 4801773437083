import { CommonModule } from '@angular/common';
import { Component, TemplateRef, ViewChild, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { upperCase } from 'lodash';
import { NzModalModule, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DeviceDetectorService } from 'ngx-device-detector';

type OsType = 'Mac' | 'iOS' | 'Android' | 'Window';

@Component({
  standalone: true,
  selector: 'by-camera-permission-info',
  imports: [
    CommonModule,
    NzModalModule,
    TranslateModule,
    NgUpperFirstPipeModule,
  ],
  templateUrl: './camera-permission-info.component.html',
  styleUrls: ['./camera-permission-info.component.scss'],
})
export class CameraPermissionInfoComponent {
  @ViewChild('cameraPermissionInfo') cameraPermissionInfoTpl: TemplateRef<any>;

  @ViewChild('cameraPermissionTitle')
  cameraPermissionTitleTpl: TemplateRef<any>;

  modalService = inject(NzModalService);

  translate = inject(TranslateService);

  os: OsType = inject(DeviceDetectorService).os as OsType;

  onShowCameraPermissionInfo() {
    if (!this.cameraPermissionInfoTpl || !this.cameraPermissionTitleTpl) {
      return;
    }

    const modal: NzModalRef = this.modalService.create({
      nzTitle: this.cameraPermissionTitleTpl,
      nzContent: this.cameraPermissionInfoTpl,
      nzFooter: [
        {
          label: upperCase(this.translate.instant('ok')),
          onClick: () => modal.close(),
          type: 'primary',
        },
      ],
    });
  }
}

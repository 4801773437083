<nz-select
  #buttonTpl
  [nzDropdownRender]="customDropdown ? customDropdown : dropdown"
  [nzSize]="size"
  [nzDropdownMatchSelectWidth]="dropdownMatchSelectWidth"
  [nzDropdownClassName]="dropdownClassName ? dropdownClassName : null"
  [nzAllowClear]="nzAllowClear"
  [compareWith]="compareFn"
  [nzMode]="mode"
  [ngModel]="currentValue"
  [nzShowArrow]="nzShowArrow"
  [nzShowSearch]="showSearch"
  [nzServerSearch]="nzServerSearch"
  [nzPlaceHolder]="placeholder"
  [nzLoading]="loading"
  [nzDisabled]="nzDisabled"
  (ngModelChange)="onModelChange($event)"
  (nzOpenChange)="onOpenChanged($event)"
  (nzOnSearch)="onSearch($event)"
  [nzMaxTagPlaceholder]="
    mode !== 'tags' ? selectedCountCustom || selectedCount : null
  "
  [nzMaxTagCount]="mode !== 'tags' ? 0 : infinity"
  class="by-w-100 multi-select"
  [class.beddy-multi-select]="mode !== 'tags'"
  [class.multi-select--without-search]="!showSearch"
  [ngClass]="byClass"
  (nzBlur)="blur.emit()"
  [nzCustomTemplate]="customTemplate"
  [nzAutoClearSearchValue]="autoClearSearchValue"
>
  <nz-option
    *ngIf="mode !== 'default'"
    nzValue="all"
    [nzLabel]="'select_all' | translate | upperfirst"
    nzCustomContent
  >
    <span class="color--azure">{{
      'select_all' | translate | upperfirst
    }}</span>
  </nz-option>
  <ng-container *ngIf="!groupByKey">
    <nz-option
      *ngFor="let option of options"
      [nzCustomContent]="!!optionTpl"
      [nzLabel]="option | get: label : option | translate | upperfirst"
      [nzValue]="option | get: value : option"
      [nzDisabled]="option | get: disabledPath : false"
    >
      <ng-container
        *ngTemplateOutlet="
          optionTpl;
          context: {
            option: option
          }
        "
      >
      </ng-container>
    </nz-option>
  </ng-container>
  <ng-container *ngIf="groupByKey">
    <nz-option-group
      *ngFor="let group of optionsGroupByKey"
      [nzLabel]="groupLabelTpl ? groupLabelCustomTpl : groupLabelDefaultTpl"
    >
      <ng-template #groupLabelCustomTpl>
        <ng-container
          *ngTemplateOutlet="
            groupLabelTpl;
            context: {
              label: groupsNames
                ? groupsNames[group.key]
                : (group.value[0] | get: groupByLabel)
            }
          "
        ></ng-container>
      </ng-template>
      <ng-template #groupLabelDefaultTpl>
        <i *ngIf="groupIcon" [class]="groupIcon"></i>
        {{
          groupsNames
            ? groupsNames[group.key]
            : (group.value[0] | get: groupByLabel)
        }}
      </ng-template>
      <nz-option
        *ngFor="let option of group.value"
        [nzCustomContent]="!!optionTpl"
        [nzLabel]="option | get: label : option | translate | upperfirst"
        [nzValue]="option | get: value : option"
        [nzDisabled]="option | get: disabledPath : false"
      >
        <ng-container
          *ngTemplateOutlet="
            optionTpl;
            context: {
              option: option
            }
          "
        >
        </ng-container>
      </nz-option>
    </nz-option-group>
  </ng-container>
</nz-select>
<ng-template #dropdown>
  <nz-divider style="margin: 4px 0"></nz-divider>
  <div class="container-button-multiselect">
    <button nz-button nzType="primary" (click)="onClose()" nzType="primary">
      {{ 'ok' | translate | upperfirst }}
    </button>
  </div>
</ng-template>
<ng-template #selectedCount>
  <span *ngIf="showIconEye" class="icon-selected-options color--blue"
    ><i class="far fa-eye"></i
  ></span>

  <span class="color--blue"
    >{{ placeholderTranslateKey | translate | upperfirst }}:
    {{ currentValue.length }}</span
  >
</ng-template>

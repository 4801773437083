import { Pipe, PipeTransform } from '@angular/core';
import { isEmptyValue } from '../helpers';

@Pipe({
  name: 'isEmpty',
  pure: true,
})
export class IsEmptyPipe implements PipeTransform {
  transform = isEmptyValue;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSelectModule } from 'ng-zorro-antd/select';

import { PipesModule } from '../../pipes/pipes.module';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';

import { MultiSelectComponent } from './multi-select.component';

@NgModule({
  declarations: [MultiSelectComponent],
  imports: [
    CommonModule,
    NzSelectModule,
    NzDividerModule,
    NzButtonModule,
    TranslateModule,
    NgPipesModule,
    FormsModule,
    PipesModule,
  ],
  exports: [MultiSelectComponent],
})
export class MultiSelectModule {}

import { Pipe, PipeTransform } from '@angular/core';

import { removeTime } from '../helpers';

@Pipe({
  name: 'toDate',
})
export class ToDatePipe implements PipeTransform {
  transform(value?: string | number): Date {
    return removeTime(value ? new Date(value) : new Date());
  }
}

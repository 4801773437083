import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { ControlIsRequiredModule } from './control-is-required/control-is-required.module';
import { ControlIsRequiredPipe } from './control-is-required/control-is-required.pipe';
import { DiscountFormatPipe } from './discount-format.pipe';
import { DiscountPipe } from './discount.pipe';
import { FloatRoundPipe } from './float-round';
import { FormatDateModule } from './format-date/format-date.module';
import { FormatDatePipe } from './format-date/format-date.pipe';
import { GetPipeModule } from './get/get.module';
import { GetPipe } from './get/get.pipe';
import { InitialsPipe } from './initials.pipe';
import { IsEmptyPipe } from './is-empty.pipe';
import { IsEvenPipe } from './is-even.pipe';
import { MapValuesModule } from './map-values/map-values.module';
import { MapValuesPipe } from './map-values/map-values.pipe';
import { PercentagePipe } from './percentage-of';
import { PurifyTextPipe } from './purify-text.pipe';
import { ReplaceAllPipe } from './replace.all.pipe';
import { SafeHtmlModule } from './safe-html/safe-html.module';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SameDayPipe } from './same-day.pipe';
import { ShortNumberModule } from './short-number/short-number.module';
import { ShortNumberPipe } from './short-number/short-number.pipe';
import { SnakeCasePipe } from './snake-case.pipe';
import { StringifyDaysDifferencePipe } from './stringify-days-difference.pipe';
import { ToCurrencyPipe } from './to-currency.pipe';
import { ToDatePipe } from './to-date.pipe';
import { ToStringPipe } from './to-string.pipe';
import { ToTimePipe } from './to-time.pipe';
import { UniqByPipe } from './uniq-by.pipe';
import { ValuesModule } from './values/values.module';
import { ValuesPipe } from './values/values.pipe';
import { WrapTextPipe } from './wrap-text.pipe';
import { ArraySumPipe } from './array-sum.pipe';

const PIPES = [
  ToDatePipe,
  ToCurrencyPipe,
  FloatRoundPipe,
  ToTimePipe,
  ReplaceAllPipe,
  WrapTextPipe,
  PurifyTextPipe,
  PercentagePipe,
  DiscountPipe,
  SnakeCasePipe,
  DiscountFormatPipe,
  UniqByPipe,
  SameDayPipe,
  IsEvenPipe,
  StringifyDaysDifferencePipe,
  ToStringPipe,
  IsEmptyPipe,
  InitialsPipe,
];

const PIPE_FROM_MODULES = [
  ControlIsRequiredPipe,
  FormatDatePipe,
  ValuesPipe,
  MapValuesPipe,
  SafeHtmlPipe,
  ShortNumberPipe,
  GetPipe,
  ArraySumPipe,
];

@NgModule({
  declarations: [PIPES],
  imports: [
    CommonModule,
    ControlIsRequiredModule,
    FormatDateModule,
    ValuesModule,
    MapValuesModule,
    SafeHtmlModule,
    ShortNumberModule,
    GetPipeModule,
    ArraySumPipe,
  ],
  providers: [CurrencyPipe, ToCurrencyPipe],
  exports: [PIPES, PIPE_FROM_MODULES],
})
export class PipesModule {}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'by-statistic-header-page',
  templateUrl: './statistic-header-page.component.html',
  styleUrls: ['./statistic-header-page.component.scss'],
})
export class StatisticHeaderPageComponent implements OnInit {
  @Input() widgetData;

  @Input() symbol: string;

  @Input() dateFrom: any;

  constructor() {}

  ngOnInit() {}
}

import { Pipe, PipeTransform } from '@angular/core';
import { uniqBy } from 'lodash';

@Pipe({
  name: 'uniqBy',
})
export class UniqByPipe implements PipeTransform {
  transform(array: any[], path: string): any[] {
    return uniqBy(array, path);
  }
}

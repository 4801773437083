import { Pipe, PipeTransform } from '@angular/core';

import { floatRound } from '../helpers/float-round';

@Pipe({
  name: 'discount',
  pure: true,
})
export class DiscountPipe implements PipeTransform {
  transform(
    total: number,
    discount_value: number,
    discount_type_id: number,
  ): number {
    if (!discount_value || !total) {
      return 0;
    }

    if (discount_type_id === 2) {
      return floatRound(total + discount_value);
    }

    if (discount_type_id === 3) {
      return floatRound(total + discount_value);
    }

    if (discount_type_id === 5) {
      return floatRound(total - (total * discount_value) / 100);
    }
  }
}

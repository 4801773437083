import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value, skipSanitation?: { skipHref: boolean }) {
    if (!value) {
      return;
    }

    value = value
      .replace('{{% property.media.logo.medium %}}', '')
      .replace('{{% property.media.cover.original %}}', '')
      .replace('{{% property.media.cover.original %}}', '')
      .replace('{{% property.media.logo.original %}}', '');

    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { purifyText } from '../helpers';

@Pipe({
  name: 'purifyText',
})
export class PurifyTextPipe implements PipeTransform {
  transform(text: string): string {
    return purifyText(text);
  }
}

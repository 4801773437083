import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecimalOnlyDirective } from './decimal-only.directive';

@NgModule({
  declarations: [DecimalOnlyDirective],
  imports: [CommonModule],
  exports: [DecimalOnlyDirective],
})
export class DecimalOnlyModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { replace } from 'lodash';

@Pipe({
  name: 'replaceAll',
})
export class ReplaceAllPipe implements PipeTransform {
  transform(string: any, replaceKey: any, replaceValue: any): string {
    const regex = new RegExp(replaceKey, 'g');
    return replace(string, regex, replaceValue);
  }
}

{{ currencyValue < 0 ? '-' : '' }}
{{
  currencySymbol === null || (!currencyValue && currencyValue !== 0)
    ? ''
    : currencySymbol || (currencyFromStore | async)?.symbol
}}
{{
  (currencyValue < 0 ? currencyValue * -1 : currencyValue)
    | currency: currencyCode:'':'1.2-2': currentLang
}}

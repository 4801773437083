import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'by-statistic-card',
  templateUrl: './statistic-card.component.html',
  styleUrls: ['./statistic-card.component.scss'],
})
export class StatisticCardComponent implements OnInit {
  @Input() mainPercentage = 0;

  @Input() text = '';

  @Input() sidePercentage = 0;

  constructor() {}

  ngOnInit() {}
}

<div class="camera-permission-alert">
  <i class="far fa-info-circle color--azure"></i>
  <span
    >{{ 'camera_permission_messages.short' | translate | upperfirst }}.
    @if (os === 'iOS' || os === 'Android') {
      <a
        (click)="onShowCameraPermissionInfo()"
        class="info-anchor"
        nz-button
        nzType="link"
        >{{ 'info' | translate | upperfirst }}.</a
      >
    }
  </span>
</div>

<ng-template #cameraPermissionTitle
  ><div class="bolder by-fs-16">
    {{ 'camera_permission_messages.modal_title' | translate | upperfirst }}
  </div></ng-template
>

<ng-template #cameraPermissionInfo>
  <div>
    <p>
      {{ 'camera_permission_messages.description' | translate | upperfirst }}
    </p>
    <h3 class="bolder">
      {{ 'camera_permission_messages.title' | translate | upperfirst }}
    </h3>
    @switch (os) {
      @case ('iOS') {
        <ol>
          <li>
            <span class="bolder">
              {{
                'camera_permission_messages.ios.step_1.title'
                  | translate
                  | upperfirst
              }}
            </span>

            <ul>
              <li>
                {{
                  'camera_permission_messages.ios.step_1.description'
                    | translate
                    | upperfirst
                }}
              </li>
            </ul>
          </li>

          <li>
            <span class="bolder">
              {{
                'camera_permission_messages.ios.step_2.title'
                  | translate
                  | upperfirst
              }}
            </span>

            <ul>
              <li>
                {{
                  'camera_permission_messages.ios.step_2.description'
                    | translate
                    | upperfirst
                }}
              </li>
            </ul>
          </li>

          <li>
            <span class="bolder">
              {{
                'camera_permission_messages.ios.step_3.title'
                  | translate
                  | upperfirst
              }}
            </span>
            <ul>
              <li>
                {{
                  'camera_permission_messages.ios.step_3.description'
                    | translate
                    | upperfirst
                }}
              </li>
            </ul>
          </li>

          <li>
            <span class="bolder">
              {{
                'camera_permission_messages.ios.step_4.title'
                  | translate
                  | upperfirst
              }}
            </span>
            <ul>
              <li>
                {{
                  'camera_permission_messages.ios.step_4.description'
                    | translate
                    | upperfirst
                }}
              </li>
            </ul>
          </li>
        </ol>
      }
      @case ('Android') {
        <ol>
          <li>
            <span class="bolder">
              {{
                'camera_permission_messages.android.step_1.title'
                  | translate
                  | upperfirst
              }}
            </span>

            <ul>
              <li>
                {{
                  'camera_permission_messages.android.step_1.description'
                    | translate
                    | upperfirst
                }}
              </li>
            </ul>
          </li>

          <li>
            <span class="bolder">
              {{
                'camera_permission_messages.android.step_2.title'
                  | translate
                  | upperfirst
              }}
            </span>

            <ul>
              <li>
                {{
                  'camera_permission_messages.android.step_2.description'
                    | translate
                    | upperfirst
                }}
              </li>
            </ul>
          </li>

          <li>
            <span class="bolder">
              {{
                'camera_permission_messages.android.step_3.title'
                  | translate
                  | upperfirst
              }}
            </span>
            <ul>
              <li>
                {{
                  'camera_permission_messages.android.step_3.description'
                    | translate
                    | upperfirst
                }}
              </li>
            </ul>
          </li>

          <li>
            <span class="bolder">
              {{
                'camera_permission_messages.android.step_4.title'
                  | translate
                  | upperfirst
              }}
            </span>
            <ul>
              <li>
                {{
                  'camera_permission_messages.android.step_4.description'
                    | translate
                    | upperfirst
                }}
              </li>
            </ul>
          </li>

          <li>
            <span class="bolder">
              {{
                'camera_permission_messages.android.step_5.title'
                  | translate
                  | upperfirst
              }}
            </span>
            <ul>
              <li>
                {{
                  'camera_permission_messages.android.step_5.description'
                    | translate
                    | upperfirst
                }}
              </li>
            </ul>
          </li>
        </ol>
      }
    }
  </div>
</ng-template>

import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'filterDateRange',
})
export class FilterDateRangePipe implements PipeTransform {
  transform(
    date: string,
    range: { startDate?: Date; endDate?: Date },
  ): boolean {
    if (!range) {
      return true;
    }
    if (!range.startDate && !range.endDate) {
      return true;
    }
    const _startDate = moment(range.startDate);
    const _endDate = moment(range.endDate);
    const _searchDate = moment(date);
    if (range.startDate && !range.endDate) {
      return _searchDate.isSameOrAfter(_startDate, 'days');
    }
    if (!range.startDate && range.endDate) {
      return _searchDate.isSameOrBefore(_endDate, 'days');
    }
    if (
      _searchDate.isSameOrAfter(_startDate, 'days') &&
      _searchDate.isSameOrBefore(_endDate, 'days')
    ) {
      return true;
    } else {
      return false;
    }
  }
}

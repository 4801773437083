import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[byRegexInput]',
})
export class RegexInputDirective {
  @Input() regexInputCondition = new RegExp(/^[A-Za-z0-9-_]+$/);

  constructor() {}

  @HostListener('keypress', ['$event'])
  public regexInput(event): boolean {
    return this.isValid(event.key);
  }

  isValid(value) {
    if (this.regexInputCondition.test(value)) {
      return true;
    }
    return false;
  }
}

<div [ngStyle]="legendContainerStyle">
  <ng-container
    *ngFor="
      let colorDefault of colorsDefault;
      let index = index;
      trackBy: trackByFn
    "
  >
    <ng-container *ngIf="disableChange; else changePicker">
      <div class="single-box">
        <div
          [ngClass]="colorDefault.class"
          [ngStyle]="colorPikerStyle"
          [style.background]="colorDefault.color"
        >
          <ng-container *ngIf="colorDefault?.icon">
            <i
              *ngIf="!colorDefault?.iconColor"
              class="{{ colorDefault?.icon }}"
            ></i>
            <i
              [ngStyle]="{ color: colorDefault?.iconColor }"
              *ngIf="colorDefault?.iconColor"
              class="{{ colorDefault?.icon }}"
            ></i>
          </ng-container>
        </div>
        <span class="by-ml-10">{{ colorDefault.title | translate }}</span>
      </div>
    </ng-container>

    <ng-template #changePicker>
      <div
        (colorPickerChange)="onChangeColor($event, index)"
        (cpToggleChange)="colorPickerClose($event, index)"
        [cpOutputFormat]="'hex'"
        [(cpToggle)]="colorsDefaultToggle[colorDefault.title]"
        [cpAlphaChannel]="'disabled'"
        [cpPosition]="pickerPosition"
        [(colorPicker)]="colorDefault.color"
        [cpPresetColors]="[
          colorsDefaultStandard &&
            colorsDefaultStandard[index] &&
            colorsDefaultStandard[index].color
        ]"
        [cpOKButton]="true"
        cpOKButtonClass="color-picker-button-ok"
      >
        <div class="single-box">
          <div
            [ngStyle]="colorPikerStyle"
            [ngClass]="colorDefault.class"
            [style.background]="colorDefault.color"
          ></div>

          <ng-container *ngIf="colorDefault?.icon">
            <i
              *ngIf="!colorDefault?.iconColor"
              class="{{ colorDefault?.icon }}"
            ></i>
            <i
              [ngStyle]="{ color: colorDefault?.iconColor }"
              *ngIf="colorDefault?.iconColor"
              class="{{ colorDefault?.icon }}"
            ></i>
          </ng-container>

          <i
            class="far fa-chevron-{{
              colorsDefaultToggle[colorDefault.title] ? 'up' : 'down'
            }}"
          ></i>

          <span>{{ colorDefault.title | translate }}</span>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>

<div class="collapse-container">
  <div class="collapse-row" [ngClass]="opened ? 'expanded' : null">
    <div class="titel-wrapper-collapse">
      <div *ngIf="prefixTitle" class="prefix-title-collapse">
        {{ prefixTitle }}:
      </div>
      <div class="collapse-title" [ngClass]="'bold'">{{ title }}</div>
    </div>
    <div class="collapse-actions">
      <i
        *ngIf="showEdit"
        class="cursor-pointer mr-20 fas fa-pencil-alt"
        (click)="onOpen()"
      ></i>
      <nz-switch
        [nzCheckedChildren]="checkedTemplate"
        *ngIf="showToggle"
        class="collapse-toggle"
        [nzLoading]="loading"
        [(ngModel)]="toggle"
        [nzControl]="true"
        (click)="onToggle()"
        [nzDisabled]="disabled"
      ></nz-switch>
      <i
        *ngIf="showArrow"
        (click)="onOpen()"
        class="mr-20 cursor-pointer far fa-chevron-{{ opened ? 'up' : 'down' }}"
      ></i>
    </div>
  </div>
  <div class="collapse-body">
    <div #target *ngIf="opened" (after-if)="scroll(target)">
      <nz-card
        *ngIf="opened"
        class="card-collapse card-with-shadow"
        [nzBodyStyle]="{
          background: 'white'
        }"
      >
        <ng-content></ng-content>
      </nz-card>
    </div>
  </div>
</div>
<ng-template #checkedTemplate
  ><div
    [ngStyle]="{ 'background-color': '#3cb860', width: '100%', height: '100%' }"
  ></div
></ng-template>

<div class="clearfix">
  <by-spin [nzSpinning]="compressionLoading || loading">
    <nz-upload
      [nzType]="nzType"
      [ngClass]="{ 'upload-with-custom-content': customContent }"
      [nzMultiple]="multiple"
      [nzFilter]="filters"
      [nzLimit]="nzLimit"
      [nzShowUploadList]="nzShowUploadList"
      [nzAccept]="nzFileType"
      [nzBeforeUpload]="beforeUpload"
      [nzDisabled]="nzDisabled"
    >
      <ng-container *ngIf="!customContent; else content">
        <ng-container *ngIf="!previewSrc; else preview">
          <p class="ant-upload-drag-icon"><i class="far fa-inbox"></i></p>
          <p class="ant-upload-text">{{ 'click_or_drag' | translate }}</p>
          <p class="ant-upload-hint">
            {{
              'file_support_explain'
                | translate: { types: explainTypes }
                | upperfirst
            }}
          </p>
        </ng-container>
        <ng-template #preview>
          <div class="image-container">
            <img
              class="image"
              [src]="previewSrc"
              [ngStyle]="{
                width: width + widthUnitType,
                height: height + heightUnitType
              }"
            />
            <div
              (click)="$event.stopPropagation(); $event.preventDefault()"
              class="actions"
            >
              <button
                nz-button
                nzType="primary"
                nzDanger
                nz-popconfirm
                nzPopconfirmTitle="{{
                  'actions.delete'
                    | translate: { param: 'image' | translate }
                    | upperfirst
                }}"
                class="ant-btn-icon-only by-delete-button"
                (nzOnConfirm)="onRemovePreview()"
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #content>
        <img
          [ngStyle]="customPreviewStyle"
          *ngIf="previewSrc"
          [src]="previewSrc"
          style="position: relative"
        />

        <ng-container *ngIf="!previewSrc">
          <ng-content></ng-content>
        </ng-container>

        <div class="custom-actions">
          <span
            *ngIf="previewSrc; else emptySpan"
            class="custom-actions--item custom-actions--delete"
            (click)="$event.stopPropagation(); $event.preventDefault()"
            nz-popconfirm
            nzPopconfirmTitle="{{
              'actions.delete'
                | translate: { param: 'image' | translate }
                | upperfirst
            }}"
            (nzOnConfirm)="onRemovePreview()"
          >
            <i
              class="far fa-times color--red"
              nz-tooltip
              [nzTooltipTitle]="'delete_image' | translate | upperfirst"
            ></i>
          </span>
          <span
            *ngIf="showCustomActionEditIcon"
            class="custom-actions--item custom-actions--edit"
          >
            <i
              class="fas fa-pen color--azure"
              nz-tooltip
              [nzTooltipTitle]="'edit_image' | translate | upperfirst"
            ></i>
          </span>

          <ng-template #emptySpan>
            <span>&nbsp;</span>
          </ng-template>
        </div>
      </ng-template>
    </nz-upload>

    <ng-container *ngIf="showFilesWithoutPreview">
      <div *ngFor="let attach of filesWithoutPreview">
        <ng-container *ngIf="attach.url">
          <a [href]="attach.url" target="_blank">{{ attach.name }}</a>
          <i
            class="fas fa-times color--red pointer by-ml-10"
            nz-popconfirm
            nzPopconfirmTitle="{{
              'actions.delete'
                | translate: { param: 'template_attach' | translate }
            }}"
            (nzOnConfirm)="deleteImage.emit(id)"
          ></i>
        </ng-container>
        <span *ngIf="!attach.url">{{ attach.name }}</span>
      </div>
    </ng-container>
  </by-spin>
  @if (!hasCameraPermission && !isDesktop) {
    <!-- This condition is necessary to handle an anomalous behavior of Chrome.
     In mobile, the input type="file",
     does not allow to use the camera to take a picture and upload it,
     even though the supported files are of type image.
     This possibility is only available when among the supported file types there is "application/pdf",
     https://stackoverflow.com/questions/77876374/html-input-type-file-not-working-to-pull-up-camera-for-pixel-android-14-comb
      -->
    @if (
      browser !== 'Chrome' ||
      (browser === 'Chrome' &&
        !!(filterDefault | firstOrDefault: 'application/pdf'))
    ) {
      <by-camera-permission-info></by-camera-permission-info>
    }
  }
</div>

import { BreakpointObserver } from '@angular/cdk/layout';
import { AfterViewInit, Directive, OnDestroy } from '@angular/core';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { GRID_BREAKPOINT } from '../../config';

@Directive({
  selector: '[byDisabledMobileKeyboard]',
})
export class DatePickerDisabledMobileKeyboardDirective
  implements OnDestroy, AfterViewInit
{
  private subs = new SubSink();

  isMobile$ = this.breakpointObserver
    .observe(GRID_BREAKPOINT.beforeMedium)
    .pipe(map(({ matches }) => matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private rangePicker: NzDatePickerComponent,
  ) {}

  ngAfterViewInit(): void {
    this.subs.add(
      this.isMobile$.subscribe((isMobile) => {
        if (!this.rangePicker) {
          return;
        }
        if (isMobile) {
          this.rangePicker.nzInputReadOnly = true;
        } else {
          this.rangePicker.nzInputReadOnly = false;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'by-page-header-calendar',
  templateUrl: './page-header-calendar.component.html',
  styleUrls: ['./page-header-calendar.component.scss'],
})
export class PageHeaderCalendarComponent implements OnInit {
  @Output()
  changeDateEvent: EventEmitter<Date> = new EventEmitter<Date>();

  @Input() viewButton = {
    today: true,
    arrow: true,
    calendar: true,
    title: true,
    customize: false,
  };

  @Input()
  set _date(date: string) {
    if (!date) {
      return;
    }
    this.date = moment(date, 'YYYY-MM-DD').toDate();
  }

  date = new Date();

  visible = false;

  constructor() {}

  ngOnInit() {}

  onChangeDate() {
    this.emit();
  }

  nexDayChange() {
    this.date = moment(this.date).add(1, 'day').toDate();
    this.emit();
  }

  prevDayChange() {
    this.date = moment(this.date).add(-1, 'day').toDate();
    this.emit();
  }

  todayChange() {
    this.date = new Date();
    this.emit();
  }

  private emit() {
    this.changeDateEvent.emit(this.date);
  }
}

import { Component, OnInit } from '@angular/core';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import {
  NzNotificationRef,
  NzNotificationService,
} from 'ng-zorro-antd/notification';
import { take } from 'rxjs/operators';

import { INotificationData } from '../models/notification-data.model';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'by-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  private lastSuccessNotification: NzNotificationRef;
  private lastSuccessMessage: NzMessageRef;

  constructor(
    private nzNotificationService: NzNotificationService,
    private nzMessageService: NzMessageService,
    private notificationService: NotificationService,
    private nzConfigService: NzConfigService,
  ) {
    this.nzConfigService.set('notification', {
      nzMaxStack: 5,
      nzPlacement: 'bottomLeft',
      nzPauseOnHover: true,
    });

    this.nzConfigService.set('message', {
      nzMaxStack: 3,
      nzPauseOnHover: false,
    });
  }

  public ngOnInit() {
    this.notificationService
      .notifications()
      .subscribe((data: INotificationData) =>
        window.innerWidth > 600
          ? this.createNotification(data)
          : this.createMessage(data),
      );
  }

  private createNotification({
    type,
    content,
    title,
    onClick,
  }: INotificationData) {
    if (type === 'success' && this.lastSuccessNotification) {
      this.nzNotificationService.remove(this.lastSuccessNotification.messageId);
    }

    const notificationRef = this.nzNotificationService.create(
      type,
      title,
      content,
      {
        nzDuration: type === 'error' ? 20000 : 4500,
      },
    );

    if (onClick) {
      notificationRef.onClick.subscribe(onClick);
    }

    if (type === 'success') {
      this.lastSuccessNotification = notificationRef;
    }
  }

  private createMessage({ type, content }: INotificationData) {
    if (type === 'success' && this.lastSuccessMessage) {
      this.nzMessageService.remove(this.lastSuccessMessage.messageId);
    }

    const messageRef = this.nzMessageService.create(type, content, {
      nzDuration: type === 'error' ? 20000 : 4500,
    });

    if (type === 'success') {
      this.lastSuccessMessage = messageRef;
    }
  }
}

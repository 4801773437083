import { Directive, OnDestroy, OnInit } from '@angular/core';
import { NzModalComponent } from 'ng-zorro-antd/modal';
import { SubSink } from 'subsink';

import { ModalDragService } from '../../services/modal-drag.service';

@Directive({
  selector: '[byModalDraggable]',
  providers: [ModalDragService],
})
export class ModalDraggableDirective implements OnInit, OnDestroy {
  private subs = new SubSink();

  constructor(
    private modal: NzModalComponent,
    private modalDragService: ModalDragService,
  ) {}

  ngOnInit() {
    this.subs.add(
      this.modal.afterOpen.subscribe(() => {
        this.modalDragService.handle(this.modal.getModalRef());
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

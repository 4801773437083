import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RateplansByTypeAndProperty } from '@app/models';
import { flattenDeep } from 'lodash';

@Component({
  selector: 'by-rateplans-select',
  templateUrl: './rateplans-select.component.html',
  styleUrls: ['./rateplans-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RateplansSelectComponent,
      multi: true,
    },
  ],
})
export class RateplansSelectComponent implements OnInit, ControlValueAccessor {
  @Input() options: RateplansByTypeAndProperty;

  @Input() value: any;

  @Input() multiple = false;

  @Input() set allSelectedDefault(allSelectedDefault: boolean) {
    if (
      (!this.value || !(this.value && this.value.length)) &&
      allSelectedDefault
    ) {
      this.onModelChange(['all']);
    }
  }

  @Input() typesToView = ['offer', 'plan'];

  onChange: any;

  currentValue: any[] = [];

  disabled: boolean;

  constructor() {}

  ngOnInit() {}

  writeValue(value: any): void {
    this.currentValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }

  onModelChange(value: any) {
    const hasSelectAll = value.find((v) => v === 'all');
    if (hasSelectAll) {
      this.handleSelectAll();
    } else {
      this.currentValue = value;
    }
    this.onChange(this.currentValue.filter((option) => option !== 'all'));
  }

  private handleSelectAll() {
    const allOptions = flattenDeep(
      Object.values(Object.values(this.options)[0]),
    ).map(({ id }: any) => id);
    this.currentValue =
      (this.currentValue || []).length !== allOptions.length ? allOptions : [];
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wrapText',
  pure: true,
})
export class WrapTextPipe implements PipeTransform {
  transform(text: string, startChar = '(', endChar = ')'): string {
    if (!text) {
      return '';
    }

    return startChar + text + endChar;
  }
}

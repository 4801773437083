import { Pipe, PipeTransform } from '@angular/core';
import { snakeCase } from 'lodash';

@Pipe({
  name: 'snakeCase',
  pure: true,
})
export class SnakeCasePipe implements PipeTransform {
  transform(text: string): string {
    if (!text) {
      return '';
    }

    return snakeCase(text);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToArray',
})
export class StringToArrayPipe implements PipeTransform {
  transform(value: any, param: string): string[] {
    return value.split(param);
  }
}

import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[byNumberOnly]',
})
export class NumberOnlyDirective {
  constructor() {}

  @HostListener('keypress', ['$event'])
  public numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 45 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}

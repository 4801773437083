<div #buttonsBarBox id="buttons-bar-box" class="buttons-bar-box"></div>
<div
  class="buttons-bar"
  [ngStyle]="{
    'width.px': size()
  }"
  [ngClass]="{
    width100: width100,
    'consider-bottom-scroll': considerBottomScroll
  }"
  [@showAnimation]="show ? 'show' : 'hidden'"
>
  <div class="buttons-bar__content">
    <ng-content></ng-content>
  </div>
  <div
    class="top-up-button__container"
    [ngClass]="{
      'hidden-scroll-button': verticalScroll >= 0 && verticalScroll < 50
    }"
  >
    <div
      class="top-up-button"
      [ngClass]="{
        'scroll-finished': translateBarToTop < 0 && translateBarToTop >= -50,
        'scroll-not-started': verticalScroll >= 0 && verticalScroll < 50
      }"
      (click)="scrollToTop()"
    >
      <i class="far fa-chevron-up" style="color: #fff"></i>
    </div>
  </div>
</div>

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'by-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(400, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(400, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CardComponent implements OnInit {
  @Input()
  public toggle = true;
  @Input()
  public link: string;
  @Input()
  public switch = false;
  @Input()
  public switchDisabled = false;
  @Input()
  public switchDisabledMessage = 'disabled';
  @Input()
  public iconWarning = false;
  @Input()
  public infoWarning: string;
  @Input()
  public switchData = false;
  @Input()
  public noIconToggle = false;
  @Input()
  public total: number;
  @Input()
  public symbol: string;
  @Input()
  public customClass = '';

  @Input()
  public shadow = true;
  @Input()
  public borderTop = false;
  @Input()
  public borderBottom = false;

  @Input() public layout:
    | 'reservation'
    | 'bills'
    | 'small'
    | 'default'
    | 'istat'
    | 'crm' = 'default';

  @Input()
  public crmSummary = false;
  @Output()
  switchChange = new EventEmitter();

  @Input()
  public footer = false;

  @Input()
  public borderColor: string;

  @Input()
  public animation = true;

  @Input()
  public iconBefeoreTitle: string;

  @Input()
  public icon: string;

  @Input() set showStart(showStart: boolean) {
    this.show = showStart;
    this.expandEvent.emit(this.show);
  }

  @Input()
  public set header(headerType: 'default' | 'middle' | 'small' | 'none') {
    this.headerClass = 'by-card-header-' + (headerType || 'default');
  }

  @Input()
  title = '';

  @Input()
  titleTemplate: TemplateRef<any> = null;

  @Input()
  viewHeader = true;

  @Output() expandEvent = new EventEmitter<boolean>();

  @Output() crmSummaryEvent = new EventEmitter<any>();

  @Input()
  public set border(borderType: 'left' | 'right' | 'bottom' | 'top') {
    if (!borderType) {
      this.borderClass = 'by-card-border';
      return;
    }
    this.borderClass = 'by-card-border-' + borderType;
  }

  @ContentChild('header')
  public headerTmpl: TemplateRef<any>;

  @ContentChild('content')
  public contentTmpl: TemplateRef<any>;

  @ContentChild('footer')
  public footerTmpl: TemplateRef<any>;

  public show = true;
  public borderClass: string;
  public headerClass = 'by-card-header-default';

  constructor() {}

  public ngOnInit() {}

  public showContent() {
    this.show = !this.show;
    this.expandEvent.emit(this.show);
  }

  _crmSummaryEvent() {
    this.crmSummaryEvent.emit();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: number | string, searchText: string): boolean {
    if (!items) {
      return true;
    }
    if (!searchText) {
      return true;
    }
    searchText = searchText.toLowerCase().trim();
    items = items
      .toString()
      .toLowerCase()
      .trim();
    return items.includes(searchText);
  }
}

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgCapitalizePipeModule } from '@z-trippete/angular-pipes';

@Component({
  standalone: true,
  imports: [NgCapitalizePipeModule, TranslateModule, CommonModule],
  selector: 'by-section-box',
  templateUrl: './section-box.component.html',
  styleUrls: ['./section-box.component.scss'],
})
export class SectionBoxComponent implements OnInit {
  @Input()
  public title: string;

  @Input()
  public disabled = false;

  @Input()
  backgroundColorName = 'white';

  constructor() {}

  public ngOnInit() {}
}

import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
  name: 'arraySum',
  standalone: true,
})
export class ArraySumPipe implements PipeTransform {
  transform(array: any[], path?: string): number {
    return (array || []).reduce((sum, item) => (sum += +get(item, path)), 0);
  }
}

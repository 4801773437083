import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Media } from '@app/models';
import { getBase64 } from '@core/helpers/get-base-64';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import Compressor from 'compressorjs';
import { get, isArray, last } from 'lodash';
import {
  NzUploadFile,
  NzUploadModule,
  NzUploadType,
} from 'ng-zorro-antd/upload';

import { heicToJpg } from '../../helpers';
import { NotificationService } from '../services/notification.service';
import {
  NgFirstOrDefaultPipeModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { SpinModule } from '../spin/spin.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CameraService } from '@app/services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { CameraPermissionInfoComponent } from '@app/components/camera-permission-info/camera-permission-info.component';
import { SubSink } from 'subsink';

const noop = () => {};

const IMAGE_MAX_SIZE = 20000000;

const SERVER_MAX_SIZE = 4000000;

const IMAGE_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  '', // HEIC
];

@Component({
  standalone: true,
  selector: 'by-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true,
    },
  ],
  imports: [
    SpinModule,
    CommonModule,
    NzUploadModule,
    NzButtonModule,
    TranslateModule,
    NzToolTipModule,
    NzPopconfirmModule,
    NgUpperFirstPipeModule,
    NzAlertModule,
    NzModalModule,
    CameraPermissionInfoComponent,
    NgFirstOrDefaultPipeModule,
  ],
})
export class ImageUploaderComponent implements ControlValueAccessor, OnDestroy {
  private readonly DEFAULT_ACTION = 'https://httpstat.us/200';

  @Input() set media(image: Media) {
    if (!image) {
      this.id = null;
      this.previewSrc = null;
      this.filesWithoutPreview = [];
      return;
    }

    const img = get(image, 'media', '');
    const id = image.id;
    const format = last(img.split('.'));

    if (!id) {
      return;
    }

    this.id = id;

    if (format === 'pdf') {
      this.filesWithoutPreview = [{ id, name: image.label, url: img }];
    } else {
      this.previewSrc = img;
    }

    this.fileList = [image];
  }

  @Input() multiple = false;

  @Input() loading = false;

  @Input() height: number;

  @Input() width = 100;

  @Input() heightUnitType = null;

  @Input() widthUnitType = '%';

  @Input() noPreview = false;

  @Input() action = this.DEFAULT_ACTION;

  @Input() notify = true;

  @Input() customContent = false;

  // da passare quando si abilita il customContent
  @Input() customPreviewStyle: any;

  @Input() nzDisabled = false;

  @Input() nzLimit = 0;

  @Input() nzShowUploadList = false;

  @Input() nzType: NzUploadType = 'drag';

  @Output() deleteImage = new EventEmitter<number>();

  @Output() imageUploaded = new EventEmitter();

  @Output() upload = new EventEmitter<any>();

  @Input('filterDefault') set filtersSetter(filters: string[]) {
    if (!filters) {
      return;
    }

    this.filterDefault = filters;
    this.explainTypes = filters
      .map((filter) => get(filter.split('/'), '[1]'))
      .join(', ');
  }

  @Input() showCustomActionEditIcon = true;

  @Input() showFilesWithoutPreview = true;

  filterDefault = ['image/png', 'image/jpg', 'image/jpeg', '.heic'];

  explainTypes = 'png, jpg, jpeg';

  filters = [
    {
      name: 'type',
      fn: (fileList: NzUploadFile[]) => {
        const filterFiles = fileList.filter((w) => {
          const supportedType = this.filterDefault.includes(w.type) || !w.type;

          let valid = true;

          if (!supportedType) {
            valid = false;
          }

          // Se è un'immagine
          if (this.isImage(w.type) && w.size > IMAGE_MAX_SIZE) {
            valid = false;
          }

          // Se non è un'immagine
          if (!this.isImage(w.type) && w.size > SERVER_MAX_SIZE) {
            valid = false;
          }

          return (
            valid || (!this.filterDefault.length && w.size <= SERVER_MAX_SIZE)
          );
        });

        if (filterFiles.length !== fileList.length) {
          this.notification.push({
            title: this.translate.instant('error'),
            content: this.translate.instant('file_size_or_type_not_allowed'),
            type: 'error',
          });
          return filterFiles;
        }
        return fileList;
      },
    },
  ];

  fileList: any[] = [];

  filesWithoutPreview: { id: number; url: string; name: string }[] = [];

  previewSrc: string = null;

  compressionLoading = false;

  public id: number;

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  hasCameraPermission = true;

  subs = new SubSink();

  isDesktop = inject(DeviceDetectorService).isDesktop();

  browser = inject(DeviceDetectorService).browser;

  cameraService = inject(CameraService);

  modalService = inject(NzModalService);

  checkPermissionPending = false;

  constructor(
    private notification: NotificationService,
    private translate: TranslateService,
  ) {}

  @HostListener('click', ['$event.target']) onClick(e: HTMLElement) {
    if (e?.tagName?.toLowerCase() === 'input') {
      this.onCheckCameraPermission();
    }
  }

  onCheckCameraPermission = () => {
    if (this.isDesktop) {
      return;
    }
    this.subs.unsubscribe();
    this.subs.add(
      this.cameraService
        .checkCameraPermission()
        .subscribe((hasCameraPermission) => {
          this.hasCameraPermission = hasCameraPermission;
        }),
    );
  };

  get nzFileType() {
    return this.filterDefault.join(',');
  }

  beforeUpload = (file: File) => {
    this.emitFile(file);
    return false;
  };

  async emitFile(f: File) {
    this.compressionLoading = true;

    const { file: originalOrConvertedFile, isHEIC } = await heicToJpg(f);

    let file = originalOrConvertedFile;

    if (this.isImage(f.type) && !isHEIC) {
      file = await this.compressImage(file);
    }

    this.fileList = this.fileList.concat(file);
    this.value = this.fileList;
    this.imageUploaded.emit();

    if (!this.noPreview && this.isImage(f.type)) {
      getBase64(file, (img: string) => (this.previewSrc = img));
    }

    if (!this.noPreview && !this.isImage(f.type)) {
      this.filesWithoutPreview = [
        ...(this.multiple ? this.filesWithoutPreview : []),
        { id: null, name: f.name, url: null },
      ];
    }

    this.upload.emit(file);
    this.compressionLoading = false;
  }

  onRemovePreview() {
    this.previewSrc = null;
    this.checkCurrentId();
    this.fileList = [];
    this.deleteImage.emit(this.id || null);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    if (obj && !isArray(obj)) {
      obj = [obj];
    }
    this.fileList = obj || [];
  }

  set value(fileList: any) {
    this.fileList = fileList;
    this.onChangeCallback(fileList);
  }

  get value(): any {
    return this.fileList;
  }

  private checkCurrentId() {
    if (!!!(this.fileList as any[]).find(({ id }) => id === this.id)) {
      this.id = null;
    }
  }

  private isImage(fileType: string): boolean {
    if (IMAGE_TYPES.indexOf(fileType) !== -1) {
      return true;
    }
    return false;
  }

  private compressImage(image: File) {
    return new Promise<File>(
      (resolve, reject) =>
        new Compressor(image, {
          quality: 0.8,
          maxWidth: 2560,
          maxHeight: 2560,
          convertSize: 1000000,
          success: (blob) => {
            resolve(new File([blob], image.name, { type: image.type }));
          },
          error: (error) => {
            if (image.size <= SERVER_MAX_SIZE) {
              resolve(image);
              return;
            }

            this.notification.push({
              title: this.translate.instant('error'),
              content: this.translate.instant('file_error'),
              type: 'error',
            });
            reject(error);
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

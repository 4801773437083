import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  ICoreState,
  selectCurrencies,
  selectCurrencyProperty,
} from '@core/+state/core.reducer';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TypedSimpleChanges } from '../../models/types/simple-changes-typed';
import { Subscription } from 'rxjs';

@Component({
  selector: 'by-currency-format',
  templateUrl: './currency-format.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class CurrencyFormatComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currencyValue: number;

  @Input() currencySymbol = '';

  @Input() currencyCode: string;

  @Input() currencyId: number;

  currentLang = '';

  private subs = new Subscription();

  currencyFromStore = this._storeCore.pipe(select(selectCurrencyProperty));

  currenciesFromStore = this._storeCore.pipe(select(selectCurrencies));

  constructor(
    private _translate: TranslateService,
    private _storeCore: Store<ICoreState>,
  ) {}

  ngOnChanges(changes: TypedSimpleChanges<{ currencyId: number }>): void {
    const { currencyId } = changes;

    if (currencyId && this.currencyId) {
      this.subs.add(
        this.currenciesFromStore.subscribe((currencies) => {
          this.currencySymbol = currencies?.find(
            ({ id }) => id === this.currencyId,
          )?.symbol;
        }),
      );
    }
  }

  ngOnInit() {
    this.currentLang = this._translate.currentLang;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}

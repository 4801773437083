import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'values',
  pure: true,
})
export class ValuesPipe implements PipeTransform {
  transform(value: any): any[] {
    if (!value) {
      return value;
    }

    return Object.values(value);
  }
}

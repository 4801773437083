import { Pipe, PipeTransform } from '@angular/core';

import { floatRound } from '../helpers/float-round';

import { ToCurrencyPipe } from './to-currency.pipe';

@Pipe({
  name: 'discountFormat',
  pure: true,
})
export class DiscountFormatPipe implements PipeTransform {
  constructor(private toCurrencyPipe: ToCurrencyPipe) {}
  transform(
    discountValue: string | number,
    discountTypeId: number,
    currencySymbol: string,
  ): string {
    if (discountTypeId === 3 || discountTypeId === 5) {
      return `${floatRound(+discountValue)}%`;
    }
    if (discountTypeId === 2 || discountTypeId === 4) {
      return this.toCurrencyPipe.transform(discountValue, currencySymbol);
    }
    return `${discountValue}`;
  }
}

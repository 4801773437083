import { Component, Input, OnInit } from '@angular/core';
import { range } from 'lodash';

import { IGuestConfigurationSimulation } from './models/guests-configuration.model';

@Component({
  selector: 'by-guests-simulation',
  templateUrl: './guests-simulation.component.html',
  styleUrls: ['./guests-simulation.component.scss'],
})
export class GuestsSimulationComponent implements OnInit {
  @Input() isErrorConfigurationData = false;
  @Input()
  set data(data: IGuestConfigurationSimulation) {
    if (!data) {
      return;
    }
    this._data = data;
    const totalBeds = data.extra_beds + data.free_beds;
    const end = data.max_adults <= totalBeds ? data.max_adults : totalBeds;
    const start =
      data.min_adults <= data.min_guests ? data.min_guests : data.min_adults;
    this.adultArray = range(start, end + 1);
    this.adultArray2 = range(data.min_adults, end + 1);
  }

  _data: IGuestConfigurationSimulation;
  public adultArray: number[];
  public adultArray2: number[];

  constructor() {}

  ngOnInit() {}

  public generateArrayChildren(adult) {
    const totalBeds = this._data.extra_beds + this._data.free_beds - adult;
    const end =
      this._data.max_children <= totalBeds
        ? this._data.max_children
        : totalBeds;
    let start = this._data.min_guests - adult;
    if (start <= 0) {
      start = 1;
    }
    return range(start, end + 1);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(string: string, limit?: number): string {
    let words = string.split(' ');

    if (limit) {
      words = words.slice(0, limit);
    }

    return words.map((word) => word.charAt(0)).join('');
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { floatRound } from '../helpers/float-round';

@Pipe({
  name: 'percentage',
  pure: true,
})
export class PercentagePipe implements PipeTransform {
  transform(value: number, total: number): number {
    if (!value || !total) {
      return 0;
    }

    return floatRound((value * 100) / total);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEven',
  pure: true,
})
export class IsEvenPipe implements PipeTransform {
  constructor() {}

  transform(value: number): boolean {
    if (!value) {
      return false;
    }

    return value % 2 === 0;
  }
}

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  signal,
} from '@angular/core';
import { range } from 'lodash';

import { windowScrollTop } from '../../helpers';
import { fromEvent } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'by-fixed-bottom-bar',
  templateUrl: './fixed-bottom-bar.component.html',
  styleUrls: ['./fixed-bottom-bar.component.scss'],
  animations: [
    trigger('showAnimation', [
      state('show', style('*')),
      state('hidden', style({ bottom: '-150px' })),
      transition('show <=> hidden', [animate('.2s ease-in-out')]),
    ]),
  ],
})
export class FixedBottomBarComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked, OnChanges
{
  @Input()
  modifyWidth = 0;

  @Input()
  width100 = false;

  @Input()
  considerBottomScroll = false;

  @Input()
  show = true;

  @ViewChild('buttonsBarBox')
  buttonsBarBox: ElementRef;

  translateBarToTop = 0;

  verticalScroll = 0;

  size = signal(0);

  private observer: IntersectionObserver;

  private resizeObserver: ResizeObserver;

  private subs = new SubSink();

  constructor() {}

  ngOnInit() {
    this.subs.add(
      fromEvent(document, 'scroll').subscribe(() => {
        this.verticalScroll = window.scrollY;
      }),
    );
  }

  ngAfterViewInit() {
    if (this.buttonsBarBox?.nativeElement) {
      this.resizeObserver = new ResizeObserver(this.resizeCallback.bind(this));
      this.resizeObserver.observe(this.buttonsBarBox.nativeElement);
    }
    this.setFooter();
  }

  private resizeCallback(entries: ResizeObserverEntry[]) {
    entries.forEach(({ contentRect: { width } }) =>
      this.size.set(width + (this.modifyWidth || 0)),
    );
  }

  setFooter() {
    if (!this.appFooter) {
      this.appContent.style.paddingBottom = this.show ? '80px' : '0px';
      return;
    }
    this.observer = new IntersectionObserver(
      (event) => {
        let translateBarToTop = Math.round(
          this.appFooter?.offsetHeight * event[0].intersectionRatio,
        );

        if (this.considerBottomScroll) {
          translateBarToTop -= 18;
        }

        this.translateBarToTop = translateBarToTop * -1;
      },
      { threshold: range(0, 1, 0.01) },
    );

    if (this.appFooter) {
      this.appFooter.style.paddingBottom = this.show ? '75px' : '24px';
    }

    this.observer.observe(this.appFooter);
  }

  ngOnChanges() {
    this.setFooter();
  }

  ngAfterViewChecked() {
    if (this.globalToTopButton) {
      this.globalToTopButton.style.display = 'none';
    }
  }

  ngOnDestroy() {
    if (this.appFooter && this.observer) {
      this.observer.unobserve(this.appFooter);
    }

    if (this.globalToTopButton) {
      this.globalToTopButton.style.display = 'flex';
    }

    if (this.appFooter) {
      this.appFooter.style.paddingBottom = '';
    }

    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.buttonsBarBox.nativeElement);
      this.resizeObserver.disconnect();
    }

    this.subs.unsubscribe();
  }

  scrollToTop() {
    windowScrollTop();
  }

  get appFooter(): HTMLElement {
    return document.querySelector('#by-app-footer');
  }

  get appContent(): HTMLElement {
    return document.querySelector('#app-content');
  }

  get globalToTopButton(): HTMLElement {
    return document.querySelector('.top-up-global-button');
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { floatRound } from '../helpers/float-round';

@Pipe({
  name: 'floatRound',
})
export class FloatRoundPipe implements PipeTransform {
  transform(value: number): number {
    return floatRound(value);
  }
}

<div class="editable-input">
  <strong class="by-mr-2">
    {{ label }}
  </strong>
  <ng-container *ngIf="isEditing; else labelTpl">
    <input
      style="max-width: 200px"
      class="by-mr-2"
      (keydown.enter)="onConfirm()"
      nz-input
      [(ngModel)]="_value"
      autofocus
    />
    <button
      class="by-mr-2 ant-btn-icon-only"
      nz-button
      nzSize="small"
      nzType="primary"
      (click)="onConfirm()"
    >
      <i class="far fa-check"></i>
    </button>
    <button
      nz-button
      nzSize="small"
      class="ant-btn-icon-only"
      nzDanger
      nzType="primary"
      (click)="toggleEdit(false)"
    >
      <i class="pointer far fa-times"></i>
    </button>
  </ng-container>
  <ng-template #labelTpl>
    <span class="pointer by-ml-2" (click)="toggleEdit(true)">
      <i class="{{ iconClass }} by-mr-2"></i>
      <ng-container *ngIf="priceMode">
        <by-currency-format
          [currencyValue]="_value"
          [currencySymbol]="currencySymbol"
        ></by-currency-format>
      </ng-container>
      <ng-container *ngIf="!priceMode">
        {{ _value }}
      </ng-container>
    </span>
  </ng-template>
</div>

<ng-container *ngIf="isErrorConfigurationData; else tableConfigurations">
  <nz-alert
    nzType="warning"
    [nzMessage]="'attenction' | translate | upperfirst"
    [nzDescription]="'bed_configuration_wrong' | translate | upperfirst"
  ></nz-alert>
</ng-container>

<ng-template #tableConfigurations>
  <nz-table
    *ngIf="_data"
    id="guestsConfigurationTable"
    #basicTable
    [nzFrontPagination]="false"
    [nzShowPagination]="false"
    [nzData]="[0]"
  >
    <tbody>
      <thead>
        <tr>
          <th colspan="2">
            {{ 'simulation' | translate | capitalize }}
          </th>
        </tr>
      </thead>
      <tr *ngFor="let adults of adultArray">
        <td class="text-aling-right">
          <ng-container *ngFor="let adult of [] | range: adults">
            <i class="adult-icon fas fa-male"></i>
          </ng-container>
        </td>
        <td>
          <span
            >{{ adults }}
            {{
              (adults > 1 ? 'adults' : 'adult') | translate | capitalize
            }}</span
          >
        </td>
      </tr>
      <ng-container *ngIf="_data?.max_children > 0">
        <ng-container *ngFor="let adults of adultArray2">
          <ng-container
            *ngIf="1 + adults <= _data.extra_beds + _data.free_beds"
          >
            <tr *ngFor="let child of generateArrayChildren(adults)">
              <td
                *ngIf="child + adults <= _data.extra_beds + _data.free_beds"
                class="text-aling-right"
              >
                <ng-container *ngFor="let adult of [] | range: adults">
                  <i class="adult-icon fas fa-male"></i>
                </ng-container>
                <span class="plus">+</span>
                <ng-container *ngFor="let ch of [] | range: child">
                  <i class="children-icon fas fa-child"></i>
                </ng-container>
              </td>
              <td>
                {{ adults }}
                {{ (adults > 1 ? 'adults' : 'adult') | translate | capitalize }}
                +
                {{ child }}
                {{
                  (child > 1 ? 'children' : 'child') | translate | capitalize
                }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
  </nz-table>
</ng-template>

<ng-container *ngIf="widgetData">
  <div class="div-statistic-header">
    <div class="div-left">
      <div class="box">
        <div class="title yesterday-title">
          {{ 'yesterday_cash' | translate | upperfirst }}
        </div>
        <div class="total yesterday-total">
          {{ symbol }} {{ widgetData?.yesterday | number: '1.2-2' }}
        </div>
      </div>
      <div class="box">
        <div class="title ">
          {{ 'today_cash' | translate | upperfirst }}
        </div>
        <div class="total ">
          {{ symbol }} {{ widgetData?.today | number: '1.2-2' }}
        </div>
      </div>
    </div>
    <div class="div-right">
      <ng-container
        *ngFor="let month of widgetData?.months | keys | orderBy; let index = index"
      >
        <div class="box">
          <div class="title-smaller">
            {{ 'month_code.' + [month] | translate | uppercase }}
            {{ dateFrom | date: 'yyyy' }}
          </div>
          <div class="total">
            {{ symbol }} {{ (widgetData?.months)[month] | number: '1.2-2' }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <nz-divider></nz-divider>
</ng-container>

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { FilterDateRangePipe } from '@core/pipe/filter-date-range.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { en_US, NZ_DATE_CONFIG, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ColorPickerModule } from 'ngx-color-picker';
import { QuillModule } from 'ngx-quill';

import { PageHeaderComponent } from '../components/page-header/page-header.component';
import { PageHeaderModule } from '../components/page-header/page-header.module';
import { FormatToDatePipe } from '../core/pipe/format-to-date.pipe';
import { StringToArrayPipe } from '../core/pipe/string-to-array.pipe';
import { AfterIfDirective } from '../directives/after-if/after-if.directive';
import { AfterIfDirectiveModule } from '../directives/after-if/after-if.module';
import { DatePickerDisabledMobileKeyboardModule } from '../directives/date-picker-disabled-mobile-keyboard/date-picker-disabled-mobile-keyboard.module';
import { DisabledDateFixerModule } from '../directives/disabled-date-fixer/disabled-date-fixer.module';
import { IsInArrayModule } from '../pipes/is-in-array/is-in-array-module';
import { PipesModule } from '../pipes/pipes.module';

import { FilterPipe } from './../core/pipe/filter.pipe';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { CardComponent } from './card/card.component';
import { CollapseComponent } from './collapse/collapse/collapse.component';
import { CurrencyFormatComponent } from './currency-format/currency-format.component';
import { BreadcrumbScrollDirective } from './directives/breadcrumb-scroll.directive';
import { DecimalOnlyDirective } from './directives/decimal-only/decimal-only.directive';
import { FloatingHotizontalDirective } from './directives/floating-horizontal.directive';
import { ModalDraggableDirective } from './directives/modal-draggable.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { RangePickerDirective } from './directives/range-picker/range-picker.directive';
import { RangePickerDirectiveModule } from './directives/range-picker/range-picker.directive.module';
import { RegexInputDirective } from './directives/regex-input.directive';
import { DividerComponent } from './divider/divider.component';
import { EditableInputComponent } from './editable-input/editable-input.component';
import { FixedBottomBarComponent } from './fixed-bottom-bar/fixed-bottom-bar.component';
import { FixedBottomBarModule } from './fixed-bottom-bar/fixed-bottom-bar.module';
import { GuestsSimulationComponent } from './guests-simulation/guests-simulation.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { InViewportModule } from './in-viewport/in-viewport.module';
import { LegendColorsComponent } from './legend-colors/legend-colors.component';
import { MultiSelectModule } from './multi-select/multi-select.module';
import { NotificationsComponent } from './notifications/notifications.component';
import { PageHeaderCalendarComponent } from './page-header-calendar/page-header-calendar.component';
import { RateplansSelectComponent } from './rateplans-select/rateplans-select.component';
import { ReplaceElementComponent } from './replace-element/replace-element.component';
import { SpinComponent } from './spin/spin.component';
import { SpinModule } from './spin/spin.module';
import { StatisticCardComponent } from './statistic-card/statistic-card.component';
import { StatisticHeaderPageComponent } from './statistic-header-page/statistic-header-page.component';
import { DecimalOnlyModule } from './directives/decimal-only/decimal-only.module';

import { NgOptimizedImage } from '@angular/common';
import { SectionBoxComponent } from './section-box/section-box.component';

registerLocaleData(en);

const IMPORTED_MODULE = [
  NzAffixModule,
  NzNotificationModule,
  NzMessageModule,
  NzBreadCrumbModule,
  NzCalendarModule,
  NzSpinModule,
  NzLayoutModule,
  NzCommentModule,
  NzSelectModule,
  NzDividerModule,
  NzFormModule,
  NzToolTipModule,
  NzPopoverModule,
  NzPopconfirmModule,
  NzSwitchModule,
  NzInputModule,
  NzInputNumberModule,
  NzDatePickerModule,
  NzCardModule,
  NzButtonModule,
  NzAlertModule,
  NzModalModule,
  NzTableModule,
  NzBadgeModule,
  NzDropDownModule,
  NzCheckboxModule,
  NzStepsModule,
  NzEmptyModule,
  NzResultModule,
  NzRadioModule,
  NzTabsModule,
  NzListModule,
  NzTagModule,
  NzUploadModule,
  NzCollapseModule,
  NzMentionModule,
  NzPaginationModule,
  NzAvatarModule,
  NzProgressModule,
  NzSkeletonModule,
  NzDrawerModule,
  NzTimePickerModule,
  NzTreeModule,
  NzAutocompleteModule,
  NzSliderModule,
  DatePickerDisabledMobileKeyboardModule,
  AfterIfDirectiveModule,
  DisabledDateFixerModule,
  BreadcrumbModule,
  IsInArrayModule,
  DecimalOnlyModule,
  NgOptimizedImage,
  SectionBoxComponent,
  ImageUploaderComponent,
];

const EXPORTED_COMPONENTS = [
  NotificationsComponent,
  NumberOnlyDirective,
  FloatingHotizontalDirective,
  PageHeaderCalendarComponent,
  CardComponent,
  FilterPipe,
  FilterDateRangePipe,
  CollapseComponent,
  GuestsSimulationComponent,
  ModalDraggableDirective,
  RegexInputDirective,
  LegendColorsComponent,
  EditableInputComponent,
  ReplaceElementComponent,
  RateplansSelectComponent,
  StringToArrayPipe,
];

@NgModule({
  imports: [
    ...IMPORTED_MODULE,
    CommonModule,
    FormsModule,
    RouterModule,
    ColorPickerModule,
    NgPipesModule,
    TranslateModule,
    QuillModule.forRoot(),
    DragDropModule,
    MultiSelectModule,
    PipesModule,
    InViewportModule,
    PageHeaderModule,
    SpinModule,
    RangePickerDirectiveModule,
    FixedBottomBarModule,
    ImageUploaderComponent,
    CurrencyFormatComponent,
  ],
  declarations: [
    ...EXPORTED_COMPONENTS,
    BreadcrumbScrollDirective,
    PageHeaderCalendarComponent,
    FormatToDatePipe,
    FilterPipe,
    FilterDateRangePipe,
    CollapseComponent,
    GuestsSimulationComponent,
    LegendColorsComponent,

    DividerComponent,
    StatisticCardComponent,
    StatisticHeaderPageComponent,
  ],
  exports: [
    ...EXPORTED_COMPONENTS,
    ...IMPORTED_MODULE,
    RouterModule,
    QuillModule,
    CurrencyFormatComponent,
    DividerComponent,
    FixedBottomBarComponent,
    StatisticCardComponent,
    StatisticHeaderPageComponent,
    MultiSelectModule,
    PipesModule,
    InViewportModule,
    PageHeaderComponent,
    SpinComponent,
    AfterIfDirective,
    BreadcrumbComponent,
    RangePickerDirective,
    DecimalOnlyDirective,
    ImageUploaderComponent,
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1,
      },
    },
  ],
})
export class UiModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsInArrayPipe } from './is-in-array.pipe';

@NgModule({
  declarations: [IsInArrayPipe],
  imports: [CommonModule],
  exports: [IsInArrayPipe],
})
export class IsInArrayModule {}

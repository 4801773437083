import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[byBreadcrumbScroll]',
})
export class BreadcrumbScrollDirective implements OnInit {
  constructor(private _element: ElementRef, private _router: Router) {}

  public ngOnInit() {
    this._router.events.subscribe(() => this.onWindowScroll(window.event));
  }

  @HostListener('window:scroll', ['$event'])
  public onWindowScroll(event) {
    if (!event || !event.currentTarget) {
      return;
    }
    const { pageYOffset } = event.currentTarget;
    let translateYResult = -pageYOffset;
    const { offsetHeight } = this._element.nativeElement;
    if (translateYResult <= -offsetHeight) {
      translateYResult = -offsetHeight;
    }
    this._element.nativeElement.style.transform = `translateY(${translateYResult}px)`;
  }
}

<nz-select
  #buttonTpl
  [nzDropdownRender]="dropdown"
  nzAllowClear
  style="width: 100%"
  [ngModel]="currentValue"
  [nzMode]="multiple ? 'multiple' : 'default'"
  (ngModelChange)="onModelChange($event)"
  [nzMaxTagPlaceholder]="selectedCount"
  [nzMaxTagCount]="
    multiple && (!currentValue || !(currentValue && currentValue.length))
      ? -1
      : 0
  "
  [nzShowArrow]="true"
  class="rateplans-select__select"
>
  <nz-option nzCustomContent nzValue="all">
    <span class="color--azure">
      {{ 'select_all' | translate | capitalize }}
    </span>
  </nz-option>

  <nz-option-group
    *ngIf="(options?.plan | keys)?.length && 'plan' | isInArray: typesToView"
    [nzLabel]="'rateplans' | translate | capitalize"
  >
    <ng-container *ngFor="let propertyId of options?.plan | keys">
      <nz-option
        [nzLabel]="(options?.plan)[propertyId][0]?.property_name"
        [nzValue]="propertyId"
        [nzDisabled]="true"
      >
      </nz-option>
      <ng-container *ngFor="let rateplan of (options?.plan)[propertyId]">
        <nz-option
          [nzValue]="+rateplan.id"
          [nzLabel]="rateplan.name"
        ></nz-option>
      </ng-container>
    </ng-container>
  </nz-option-group>

  <nz-option-group
    *ngIf="(options?.offer | keys)?.length && 'offer' | isInArray: typesToView"
    [nzLabel]="'offers' | translate | capitalize"
  >
    <ng-container *ngFor="let propertyId of options?.offer | keys">
      <nz-option
        [nzLabel]="(options?.offer)[propertyId][0]?.property_name"
        [nzDisabled]="true"
      >
      </nz-option>
      <ng-container *ngFor="let rateplan of (options?.offer)[propertyId]">
        <nz-option
          [nzValue]="+rateplan.id"
          [nzLabel]="rateplan.name"
        ></nz-option>
      </ng-container>
    </ng-container>
  </nz-option-group>

  <nz-option-group
    *ngIf="
      (options?.bundle | keys)?.length && 'bundle' | isInArray: typesToView
    "
    [nzLabel]="'bundles' | translate | capitalize"
  >
    <ng-container *ngFor="let propertyId of options?.bundle | keys">
      <nz-option
        [nzLabel]="(options?.bundle)[propertyId][0]?.property_name"
        [nzDisabled]="true"
      >
      </nz-option>
      <ng-container *ngFor="let rateplan of (options?.bundle)[propertyId]">
        <nz-option
          [nzValue]="+rateplan.id"
          [nzLabel]="rateplan.name"
        ></nz-option>
      </ng-container>
    </ng-container>
  </nz-option-group>
</nz-select>

<ng-template #dropdown>
  <nz-divider style="margin: 4px 0"></nz-divider>
  <div class="container-button-multiselect">
    <button nz-button (click)="buttonTpl.nzOpen = false" nzType="primary">
      {{ 'ok' | translate | capitalize }}
    </button>
  </div>
</ng-template>

<ng-template #selectedCount>
  {{ 'rateplan_selected' | translate | capitalize }} :
  {{ (currentValue && currentValue.length) || 0 }}
</ng-template>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'by-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.scss'],
})
export class DividerComponent implements OnInit {
  @Input() byType = '';

  @Input() bySpaceAround = false;

  divClass = '';

  constructor() {}

  ngOnInit() {
    if (this.bySpaceAround) {
      this.divClass = this.byType;
    }
  }
}

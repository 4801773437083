<nz-card
  [ngClass]="{
    'card-with-shadow': shadow,
    'card-crm': layout === 'crm' && !show,
    'card-crm-summary': layout === 'crm' && crmSummary,
    'border-top': borderTop,
    'border-bottom': borderBottom,
    'card-crm-show': layout === 'crm' && show,
    'animation-card-res': !show && animation
  }"
  class="{{ show || !toggle ? borderClass : null }} {{ customClass }}"
  [ngStyle]="{ 'border-color': borderColor }"
>
  <div
    [ngClass]="headerClass"
    *ngIf="toggle && viewHeader"
    class="by-card-header {{ layout === 'crm' && !show ? 'header-crm' : null }}"
    nz-row
    nzAlign="middle"
    nzJustify="between"
    style="flex-flow: initial"
  >
    <div
      class="by-card-title"
      [ngClass]="{ 'flex-header': switch }"
      nz-col
      *ngIf="!crmSummary"
    >
      <div style="display: inline">
        <div (click)="!noIconToggle ? showContent() : null">
          <i
            *ngIf="
              layout !== 'reservation' && layout !== 'crm' && !noIconToggle
            "
            class="far fa-chevron-down rotation-arrow"
            [ngClass]="{ 'to-top': show }"
            [ngStyle]="{ display: layout === 'istat' ? 'none' : 'inline' }"
          ></i>
          <i
            *ngIf="layout == 'istat'"
            class="fas fa-caret-down rotation-arrow arrow-istat"
            [ngClass]="{ 'to-top': show }"
          ></i>
          <i
            *ngIf="iconBefeoreTitle"
            class="{{ iconBefeoreTitle }} by-mr-2"
          ></i>

          <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
          {{ title }}

          <i
            *ngIf="(layout === 'reservation' || layout === 'crm') && !icon"
            class="icon-reservation far fa-{{ show ? 'minus' : 'plus' }}"
          ></i>
        </div>
        <ng-container *ngIf="link">
          <a class="by-ml-2" target="_blank" [href]="link"
            ><i class="far fa-external-link"></i
          ></a>
        </ng-container>
      </div>

      <i
        *ngIf="(layout === 'reservation' || layout === 'crm') && icon"
        class="icon-reservation-custom {{ icon }}"
      ></i>
      <i
        *ngIf="layout === 'small' && icon"
        nz-tooltip
        [nzTooltipTitle]="
          (icon === 'eye' ? 'send_guest' : 'hide_guest') | translate
        "
        class="icon-reservation-custom {{ icon }}"
      ></i>
      <div class="flex-header">
        <i
          *ngIf="iconWarning"
          nz-tooltip
          [nzTooltipTitle]="infoWarning | lowercase | upperfirst"
          class="icon-warning fas fa-exclamation-triangle"
        ></i>

        <nz-switch
          nz-tooltip
          [nzTooltipTitle]="
            switchDisabled
              ? (switchDisabledMessage | translate | upperfirst)
              : null
          "
          *ngIf="switch"
          [(ngModel)]="switchData"
          (ngModelChange)="switchChange.emit($event)"
          [nzDisabled]="switchDisabled"
        ></nz-switch>
      </div>
      <div class="by-card-total-div" *ngIf="layout === 'bills'">
        <b>
          {{ 'total' | translate | capitalize }}:
          <by-currency-format
            [currencyValue]="total || 0"
            [currencySymbol]="symbol"
          ></by-currency-format
        ></b>
      </div>
    </div>

    <div
      class="by-card-title by-card-title--crm_summary"
      nz-col
      *ngIf="crmSummary"
    >
      <i
        *ngIf="layout !== 'reservation' && layout !== 'crm'"
        class="far fa-chevron-down rotation-arrow"
        [ngClass]="{ 'to-top': show }"
        [ngStyle]="{ display: layout === 'istat' ? 'none' : 'inline' }"
      ></i>
      <i
        *ngIf="layout == 'istat'"
        class="fas fa-caret-down rotation-arrow arrow-istat"
        [ngClass]="{ 'to-top': show }"
      ></i>
      <span class="title__text">
        <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
        {{ title }}</span
      >
      <div>
        <div>
          <button
            nz-tooltip
            (click)="_crmSummaryEvent()"
            class="btn-no-style action pointer"
            [nzTooltipTitle]="'change_prices' | translate | upperfirst"
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
        </div>
        <nz-divider
          nzType="vertical"
          style="margin: 0 14px 0 9px !important; height: 35px"
        ></nz-divider>
        <i
          *ngIf="(layout === 'reservation' || layout === 'crm') && !icon"
          class="icon-reservation card-opening-icon pointer far fa-{{
            show ? 'minus' : 'plus'
          }}"
          (click)="showContent()"
        ></i>
        <i
          *ngIf="
            (layout === 'reservation' ||
              layout === 'crm' ||
              layout === 'small' ||
              layout === 'istat') &&
            icon
          "
          class="icon-reservation-custom card-opening-icon pointer {{ icon }}"
          (click)="showContent()"
        ></i>
      </div>
    </div>

    <div nz-col class="right-template">
      <ng-container *ngTemplateOutlet="headerTmpl"></ng-container>
    </div>
  </div>
  <div
    class="{{ layout !== 'small' ? 'by-mt-2' : null }}"
    [@fadeInOut]
    *ngIf="show || !toggle"
  >
    <nz-divider *ngIf="toggle && viewHeader"></nz-divider>
    <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
    <div class="by-card-footer" *ngIf="footer">
      <nz-divider></nz-divider>
      <ng-container *ngTemplateOutlet="footerTmpl"></ng-container>
    </div>
  </div>
</nz-card>

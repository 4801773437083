import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'by-replace',
  templateUrl: './replace-element.component.html',
  styleUrls: ['./replace-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReplaceElementComponent implements OnInit {
  @Input()
  defaultElement: TemplateRef<any>;

  @Input()
  hoverElement: TemplateRef<any>;

  @Input()
  containerClasName = null;

  showDefaultElement = true;

  constructor() {}

  ngOnInit() {}

  onMouseOver() {
    this.showDefaultElement = false;
  }

  onMouseOut() {
    this.showDefaultElement = true;
  }
}

<div class="by-page-header-calendar">
  <div class="by-page-header-calendar__container">
    <div class="by-page-header-calendar__container__tools">
      <div class="by-page-header-calendar__container__buttons">
        <button
          *ngIf="viewButton['calendar']"
          [nzSize]="'small'"
          nz-button
          nzType="primary"
          nzPopoverPlacement="bottomLeft"
          nz-popover
          [(nzPopoverVisible)]="visible"
          nzPopoverTrigger="click"
          class="ant-btn-icon-only"
          [nzPopoverContent]="contentTemplate"
          nzPopoverOverlayClassName="page-header-calendar-popover"
        >
          <i class="fal fa-calendar-alt"></i>
        </button>
        <button
          *ngIf="viewButton['today']"
          class="today by-ml-3"
          [nzSize]="'small'"
          nz-button
          nzType="primary"
          (click)="todayChange()"
        >
          {{ 'today' | translate | upperfirst }}
        </button>
        <button
          *ngIf="viewButton['arrow']"
          class="prevDay by-ml-3 ant-btn-icon-only"
          [nzSize]="'small'"
          nz-button
          nzType="primary"
          (click)="prevDayChange()"
        >
          <i class="far fa-angle-left"></i>
        </button>
        <button
          *ngIf="viewButton['arrow']"
          class="nextDay by-ml-3 ant-btn-icon-only"
          [nzSize]="'small'"
          nz-button
          nzType="primary"
          (click)="nexDayChange()"
        >
          <i class="far fa-angle-right"></i>
        </button>
      </div>
      <div *ngIf="viewButton['title']" nz-col>
        <span class="date">{{ date | formatDate : 'dddd, DD MMMM YYYY' }}</span>
      </div>
    </div>
    <div class="by-page-header-calendar__content">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="rectangle"></div>
</div>
<ng-template #contentTemplate>
  <div [ngStyle]="{ width: '260px' }">
    <nz-calendar
      [nzFullscreen]="false"
      [(ngModel)]="date"
      (ngModelChange)="onChangeDate()"
    ></nz-calendar>
  </div>
</ng-template>

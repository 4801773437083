import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ICollapseEvent {
  toggleStatus: boolean;
  itemId: number;
}

@Component({
  selector: 'by-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
})
export class CollapseComponent implements OnInit {
  @Input()
  disabled = false;
  @Input()
  toggle = false;
  @Input()
  opened = false;
  @Input()
  title: string;
  @Input()
  showEdit = true;
  @Input()
  showToggle = true;
  @Input()
  showArrow = false;
  @Input()
  prefixTitle: string;
  @Input()
  itemId: number;
  @Input()
  loading = false;
  @Input()
  scrol = true;

  @Output()
  toggleEvent: EventEmitter<ICollapseEvent> = new EventEmitter<
    ICollapseEvent
  >();

  constructor() {}

  ngOnInit() {}

  onToggle() {
    this.toggle = !this.toggle;
    this.toggleEvent.emit({ toggleStatus: this.toggle, itemId: this.itemId });
  }

  onOpen() {
    this.opened = !this.opened;
  }

  scroll(target: HTMLDivElement) {
    if (!target) {
      return;
    }
    if (this.scrol) {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  }
}

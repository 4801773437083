import { Component, Input, OnInit } from '@angular/core';
import { UserPreferencesStoreActions } from '@app/root-store/user-preferences-store';
import { ICoreState } from '@core/+state/core.reducer';
import { IPreferenceUpdate } from '@core/models/api/generics/preferences/preference.model';
import { Store } from '@ngrx/store';

interface Color {
  title: string;
  color: string;
  icon?: string;
  iconColor?: string;
  id?: number;
  class?: string;
}

@Component({
  selector: 'by-legend-colors',
  templateUrl: './legend-colors.component.html',
  styleUrls: ['./legend-colors.component.scss'],
})
export class LegendColorsComponent implements OnInit {
  @Input()
  colorsDefault: Color[];

  @Input()
  mapPreferenceToSendFn: (preferences?: IPreferenceUpdate) => IPreferenceUpdate;

  @Input()
  colorsDefaultStandard: Color[];

  @Input() colorsDefaultToggle = {};

  @Input() category: string;

  @Input() actionType: 'default' | 'reservation_tag' = 'default';

  @Input() disableChange: boolean;

  @Input() pickerPosition = 'top';

  @Input() preferenceUpdateKey: keyof Color;

  @Input() colorPikerStyle = {
    width: '16px',
    height: '16px',
    'text-align': 'center',
  };

  @Input() legendContainerStyle = {
    display: 'flex',
    'justify-content': 'flex-start',
    'flex-wrap': 'wrap',
    padding: '15px',
    'margin-bottom': '6px',
  };

  preferences: IPreferenceUpdate = { options: {} };

  constructor(private _coreStore: Store<ICoreState>) {}

  trackByFn(_, color: Color) {
    return color.title;
  }

  public ngOnInit() {}

  public onChangeColor(event, index) {
    if (!event || this.actionType === 'reservation_tag') {
      return;
    }

    const key = this.colorsDefault[index][this.preferenceUpdateKey];

    const defaultKey = this.colorsDefault[index].title + '-color';

    this.preferences = {
      options: {
        [this.category]: {
          [key || defaultKey]: event,
        },
      },
    };
  }

  public colorPickerClose(isOpen: boolean, index: number) {
    if (isOpen) {
      return;
    }

    /**
     * Commento il codice sottostante perché non ho capito se questo codice è utilizzato da qualche parte.
     * La modifica è andata online il 27/12/2023, se dopo qualche mese nessuno si lamenta possiamo scippare.
     */

    // if (this.actionType === 'reservation_tag') {
    //   const {
    //     title: label,
    //     id: reservation_tag_id,
    //     color,
    //   } = this.colorsDefault[index];
    //   const request = { label, reservation_tag_id, color };
    //   this._rootStore.dispatch(
    //     new ReservationTagStoreActions.UpdateRequestAction({
    //       request,
    //       reload: true,
    //     }),
    //   );
    //   return;
    // }

    if (this.mapPreferenceToSendFn) {
      this.preferences = {
        ...this.mapPreferenceToSendFn(this.preferences),
      };
    }

    if (Object.keys(this.preferences?.options).length) {
      this._coreStore.dispatch(
        new UserPreferencesStoreActions.UpdateRequestAction({
          preferences: this.preferences,
        }),
      );
      this.preferences = { options: {} };
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DatePickerDisabledMobileKeyboardDirective } from './date-picker-disabled-mobile-keyboard.directive';

@NgModule({
  declarations: [DatePickerDisabledMobileKeyboardDirective],
  imports: [CommonModule],
  exports: [DatePickerDisabledMobileKeyboardDirective],
})
export class DatePickerDisabledMobileKeyboardModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
  name: 'mapValues',
})
export class MapValuesPipe implements PipeTransform {
  transform(array: any[], mapper: string | ((item: any) => any)): any[] {
    if (typeof mapper === 'string') {
      return (array || []).map((item) => get(item, mapper));
    }

    return (array || []).map(mapper);
  }
}

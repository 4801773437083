import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isNil } from 'lodash';

@Component({
  selector: 'by-editable-input',
  templateUrl: './editable-input.component.html',
  styleUrls: ['./editable-input.component.scss'],
})
export class EditableInputComponent implements OnInit {
  @Input()
  priceMode = false;

  @Input()
  currencySymbol: string;

  @Input()
  set value(value: string) {
    this._value = value;
    this._valueBackup = value;
  }

  @Input()
  label: string;

  @Input()
  iconClass: 'fas fa-pencil-alt';

  @Output()
  confirm = new EventEmitter<string>();

  isEditing = false;
  _value: string;
  _valueBackup: string;

  constructor() {}

  ngOnInit() {}

  onConfirm() {
    let valueToSend;
    this.priceMode
      ? (valueToSend = parseFloat(this._value))
      : (valueToSend = this._value);
    if (isNil(valueToSend)) {
      this._value = this._valueBackup;
      return;
    }
    this.confirm.emit(valueToSend);
    this.toggleEdit(false);
  }

  toggleEdit(status: boolean) {
    this.isEditing = status;
    this._value = this._valueBackup;
  }
}

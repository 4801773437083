import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[byFloatingHorizontal]',
})
export class FloatingHotizontalDirective {
  constructor(private _element: ElementRef) {}

  @HostListener('scroll', ['$event'])
  public onScrollFake(e) {
    const elementsScrollFake: any = this._element.nativeElement.scrollLeft;
    const elementScroll = document.querySelector('#sticky .ant-table-body');
    const element = <HTMLElement>document.querySelector('#sticky-arrow');

    const { scrollWidth, clientWidth } = elementScroll;

    if (element) {
      if (
        elementsScrollFake + 100 + clientWidth >= scrollWidth ||
        scrollWidth === 0
      ) {
        element.style.display = 'flex';
      } else {
        element.style.display = 'none';
      }
    }

    e = e || window.event;
    elementScroll.scrollTo({
      left: elementsScrollFake,
    });
  }
}
